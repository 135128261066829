// Generated by Framer (83eb5d8)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS, withFX } from "framer";
import { LayoutGroup, MotionConfigContext } from "framer-motion";
import * as React from "react";
const ImageWithFX = withFX(Image);

const cycleOrder = ["rOnJy4McM"];

const serializationHash = "framer-NeBb7"

const variantClassNames = {rOnJy4McM: "framer-v-9mcw2g"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const animation = {opacity: 0, rotate: 0, scale: 1, x: 0, y: 50}

const transition1 = {delay: 0, duration: 0.8, ease: [0.44, 0, 0.56, 1], type: "tween"}

const animation1 = {opacity: 0, rotate: 0, scale: 1, transition: transition1, x: 0, y: 50}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, image, tap, width, ...props}) => { return {...props, fzgofG0mc: image ?? props.fzgofG0mc ?? {src: "https://framerusercontent.com/images/pOAdYNeoyRGELfDas1OiT7s9A.png?scale-down-to=1024", srcSet: "https://framerusercontent.com/images/pOAdYNeoyRGELfDas1OiT7s9A.png?scale-down-to=512 512w, https://framerusercontent.com/images/pOAdYNeoyRGELfDas1OiT7s9A.png?scale-down-to=1024 1024w, https://framerusercontent.com/images/pOAdYNeoyRGELfDas1OiT7s9A.png 1600w"}, G0rjwTVSp: tap ?? props.G0rjwTVSp} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;tap?: any;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, G0rjwTVSp, fzgofG0mc, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "rOnJy4McM", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1wofxwd = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (G0rjwTVSp) {
const res = await G0rjwTVSp(...args);
if (res === false) return false;
}
})

const transforms = restProps.transformTemplate?.(undefined, "") ?? ""

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><ImageWithFX {...restProps} __framer__animate={{transition: transition1}} __framer__animateOnce __framer__enter={animation} __framer__exit={animation1} __framer__styleAppearEffectEnabled __framer__threshold={0} __perspectiveFX={false} __smartComponentFX __targetOpacity={1} animate={variants} background={{alt: "", fit: "fill", intrinsicHeight: 1170, intrinsicWidth: 1600, pixelHeight: 1170, pixelWidth: 1600, sizes: "min(800px, 100vw)", ...toResponsiveImage(fzgofG0mc)}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-9mcw2g", className, classNames)} data-framer-name={"Default"} data-highlight initial={variant} layoutDependency={layoutDependency} layoutId={"rOnJy4McM"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={onTap1wofxwd} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{borderBottomLeftRadius: 16, borderBottomRightRadius: 16, borderTopLeftRadius: 16, borderTopRightRadius: 16, ...style}} transformTemplate={(_, t) => `${transforms} perspective(1200px) ${t}`}/></Transition>
</LayoutGroup>)

});

const css = [".framer-NeBb7[data-border=\"true\"]::after, .framer-NeBb7 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-NeBb7.framer-1njyba, .framer-NeBb7 .framer-1njyba { display: block; }", ".framer-NeBb7.framer-9mcw2g { cursor: pointer; height: 585px; max-width: 800px; overflow: hidden; position: relative; width: 800px; will-change: var(--framer-will-change-override, transform); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 585
 * @framerIntrinsicWidth 800
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"],"constraints":[null,"800px",null,null]}}}
 * @framerVariables {"G0rjwTVSp":"tap","fzgofG0mc":"image"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerFbMGEGTFr: React.ComponentType<Props> = withCSS(Component, css, "framer-NeBb7") as typeof Component;
export default FramerFbMGEGTFr;

FramerFbMGEGTFr.displayName = "Image + overlay";

FramerFbMGEGTFr.defaultProps = {height: 585, width: 800};

addPropertyControls(FramerFbMGEGTFr, {G0rjwTVSp: {title: "Tap", type: ControlType.EventHandler}, fzgofG0mc: {__defaultAssetReference: "data:framer/asset-reference,pOAdYNeoyRGELfDas1OiT7s9A.png?originalFilename=dsCheckbox.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerFbMGEGTFr, [])